<template>
  <div class="game-container">

     <!-- These elements are always shown no matter the game state -->
    <div class="d-flex flex-row justify-content-center">
      <Navbar 
        :showBackButton="false"
        class="align-self-start"></Navbar> 
      <div class="align-self-center mt-2 flex-grow-1">
        <ClueGiverList class="mx-auto px-2"></ClueGiverList>
      </div>
    </div>
      <Scoreboard></Scoreboard>

     <div v-if="getRoomStatus === $getConst('ROOM_STARTING_GAME')" class="d-flex justify-content-center my-3">
      <h3 class="box-default rounded p-2 text-center d-inline-block">
        {{$t('view.game.gameStarts')}} {{getCurrentTime.second}}...
      </h3>
    </div>


    <!-- This is what the player will see once a round is currently being played -->
    <div v-else-if="getRoomStatus === $getConst('ROOM_PLAYING_GAME')">
      <Timer></Timer>

      <Card v-if="getCurrentClueGiver.name === getMyUsername"></Card>

      <div v-else class="d-flex justify-content-center">
        <h3 class="box-default rounded p-2 text-center d-inline-block">
          {{getCurrentGuessingTeam === getMyTeam ? $t('view.game.yourTeamGuessing') : $t('view.game.notYourTurn')}}
        </h3>
      </div>

    </div>

    <!-- This is what the player will see once a round ends -->
    <RoundOver
      v-else-if="getRoomStatus === $getConst('ROOM_TRANSITION_GAME')"
    ></RoundOver>

    <!-- This is what the player will see once the game is over -->
    <GameOver
      v-else-if="getRoomStatus === $getConst('ROOM_WIN_GAME')"
    ></GameOver>


  </div>
</template>

<script>

import ClueGiverList from '@/components/game/ClueGiverList.vue'
import Navbar from '@/components/Navbar.vue'
import Scoreboard from '@/components/game/Scoreboard.vue'
import Timer from '@/components/game/Timer.vue'
import Card from '@/components/game/Card.vue'
import GameOver from '@/components/game/GameOver.vue'
import RoundOver from '@/components/game/RoundOver.vue'

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  title() {
    return this.$t('view.game.name')
  },
  data() {
    return {
    }
  },
  components: {
    ClueGiverList,
    Timer,
    Navbar,
    Scoreboard,
    Card,
    GameOver,
    RoundOver,
  },
  methods: {
    ...mapActions([
      'closePlayerGameWs',
      'getUserData',
    ])
  },
  computed: {
    ...mapState({
      playerGameSocket: state => state.game.playerGameSocket,
    }),
    ...mapGetters([
      'getRoomStatus',
      'getMyUsername',
      'getCurrentTime',
      'getCurrentClueGiver',
      'getMyTeam',
      'getCurrentGuessingTeam',
    ]),
  },
  beforeRouteEnter (to, from, next) {
    // assure that the getUserData() function is called so we can display UI for CCC
    // by comparing getMyUsername value to current CCC (getUserData allows for determining username)
    next( vm => {
      vm.getUserData();
    });
  },
    beforeRouteLeave (to, from, next) {
    if (this.playerGameSocket != undefined) {
      alert(this.$t('view.game.leaveMsg'));
      this.closePlayerGameWs();
    }
    next();
  }
}
</script>
