<template>
  <b-container class="mt-n2">

    <b-row align-h="center" align-v="center" class="h-25">
      <b-col v-for="team in getCurrentPoints" :key="team.color" cols="6" sm="4" lg="3">

        <h3 
          style="font-size: 2rem;"
          v-if="team.color == getCurrentGuessingTeam" 
          class="text-center" >{{teamIcon}}
        </h3>

        <b-card
          class="scoreboard"
					:bg-variant="(isDarkTheme ? 'dark': 'light')"
					:body-text-variant="(isDarkTheme ? 'light': 'dark')"
					:footer-text-variant="(isDarkTheme ? 'light': 'dark')"
					:header-text-variant="(isDarkTheme ? 'light': 'black')"
          :border-variant="team.color == getCurrentGuessingTeam ? 'success' : 'dark'"
          :style="team.color == getCurrentGuessingTeam ? 'border-width:3px' : 'border-width:1px'"
          :header-bg-variant="team.color === $getConst('RED') ? 'danger' : 'primary'"
          align="center"
        >
          <template #header>
            <span>
              {{(team.color === $getConst('RED') ? $t('view.game.red') : $t('view.game.blue'))}}
              <span v-if="team.color === getWinningTeam">👑</span>
            </span>
          </template>

          <b-card-text 
            class="scoreboard-team-list-container"
          >

              <!-- RED PLAYERS -->
              <div v-if="team.color === $getConst('RED')">
                <ul class="fa-ul">
                  <li v-for="player in getRedPlayers" :key="player.username">
                    <span class="fa-li">
                      <i class="fa fa-user-o icon-state-default"></i>
                    </span>
                    {{ player.username }}{{ getCurrentClueGiver.name === player.username ? ' 🔍' : ''}}
                  </li>
                </ul>
              </div>

              <!-- BLUE PLAYERS -->
              <div v-else-if="team.color === $getConst('BLUE')">
                <ul class="fa-ul">
                  <li v-for="player in getBluePlayers" :key="player.username">
                    <span class="fa-li">
                      <i class="fa fa-user-o icon-state-default"></i>
                    </span>
                    {{ player.username }}{{ getCurrentClueGiver.name === player.username ? ' 🔍' : ''}}
                  </li>
                </ul>
              </div>

          </b-card-text>


          <template #footer>
            <div
            style="font-size:1.1rem;" 
            class="text-default font-weight-normal">
            {{`${team.points}/${getWinPoints} ${scoreIcon}`}}
            </div>
          </template>


        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
    teamIcon() {
      if (this.getGameMode === this.$getConst('TNT') && this.getRoomStatus === this.$getConst('ROOM_PLAYING_GAME')) {
        return '💣'
      }
      if (this.getGameMode === this.$getConst('TNT') && this.getRoomStatus === this.$getConst('ROOM_TRANSITION_GAME')) {
        return '💥'
      }
      else return null
    },
    scoreIcon() {
      if (this.getGameMode === this.$getConst('TNT')) {
        return '❤️'
      }
      else if (this.getGameMode === this.$getConst('ARCADE')) {
        return '💰'
      }
      else return null
    },
    ...mapGetters([
      'getCurrentGuessingTeam',
      'getCurrentPoints',
      'getGameMode',
      'getRoomStatus',
      'getWinPoints',
      'getWinningTeam',
      'getCurrentClueGiver',
      'getRedPlayers',
      'getBluePlayers',
      'getMyUsername',
    ]),
  }
}
</script>
