<template>
  <b-container class="game-timer my-3">
    <b-row align-v="center" align-h="center">
      <!-- cols set to 0 here so that the timer b-col is centered horizontally -->
      <b-col cols="0">
        <span class="clock-icon-container">
          <b-icon icon="clock" :animation="spinClock" scale="0.9"></b-icon>
        </span>
      </b-col>
      <b-col cols="auto">
      <!-- format the display of the timer as 00:00 -->
      <span v-if="getCurrentTime.minute<10">0</span>{{getCurrentTime.minute}}:<span v-if="getCurrentTime.second<10">0</span>{{getCurrentTime.second}}
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      // set as empty string for no clock animation
      // set to 'spin' for clock spin animation
      spinClock: '',  
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentTime',
    ])
  }
}
</script>