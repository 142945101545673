<template>
	<div>
		<div class="d-flex justify-content-center my-2">
			<b-card
				:bg-variant="isDarkTheme ? 'dark' : 'light'"
				:text-variant="isDarkTheme ? 'light' : 'dark'"
				:border-variant="isDarkTheme ? '' : 'secondary'"
				footer-border-variant=""
				class="text-center game-card d-flex flex-grow-1 flex-sm-grow-0"
				no-body
			>
				<b-card-body class="d-flex align-items-center justify-content-center">
					<div>
						{{
							currentCard.content === "" ? "No card available" : currentCard.content
						}}
					</div>
				</b-card-body>

				<!-- a hint should only show if the current card has one associated -->
				<template v-if="isHintVisible" #footer>
					<div class="card-hint">
						<em>{{ currentCard.hints[hintIndex].content }}</em>
					</div>
				</template>
			</b-card>
		</div>

		<div class="game-buttons d-flex justify-content-center my-2">
			<b-button size="lg" class="skip-button mx-2" @click="skipCard">
				{{ "skip" }}
			</b-button>
			<b-button size="lg" class="correct-button mx-2" @click="correctCard">
				{{ "correct" }}
			</b-button>
			<b-button size="lg" :disabled="checkForHint() ? false : true" class="hint-button mx-2" @click="toggleHint">
				{{ "hint" }}
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data() {
		return {
			hintIndex: 0,
			isHintVisible: false,
		};
	},
	methods: {
    // ADD NEXT HINT OPTION!!!!! We only see one hint *****
    skipCard() {
      this.isHintVisible = false;
      this.nextCard();
    },
    correctCard() {
      this.isHintVisible = false;
      this.correctGuess();
    },
    checkForHint() {
      if (this.currentCard == undefined) {
        return false;
      }
      else if (this.currentCard.hints.length == 0 || this.currentCard.hints[this.hintIndex].content == "") {
        return false;
      }
      else {
        return true;
      }
    },

		toggleHint() {
			if (this.checkForHint == false) {
				this.isHintVisible = false;
			} else {
				this.isHintVisible = !this.isHintVisible;
			}
		},

		...mapActions(["nextCard", "correctGuess"]),
	},

	computed: {
		...mapState({
			isDarkTheme: (state) => state.settings.isDarkTheme,
      currentCard: (state) => state.game.currentCard,
		}),
	},
};
</script>
