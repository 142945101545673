<template>

<!-- for right now just show the current clue giver, then add a list later -->
  <div class="clue-giver-list-container">
        <h5 :class="[(isDarkTheme ? 'text-light' : 'text-dark'), 'text-center']">{{$t('view.game.currentClueGiver')}} 🔍</h5>
        <div class="clue-giver-list rounded mb-3">
            <ul class="fa-ul">
              <li>
                <span class="fa-li">
                  <i class="fa fa-user-o icon-state-default"></i>
                </span>
                {{ getCurrentClueGiver != undefined ? getCurrentClueGiver.name : '---' }}
              </li>
            </ul>
        </div>
  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
    ...mapGetters([
      'getCurrentClueGiver'
    ])
  }
}
</script>