<template>
  <b-container class="my-3">
    <div class="d-flex justify-content-center">
      <div class="box-default rounded p-2 text-center d-inline-block">
        <h3>{{$t('view.game.inbetweenRoundTitle')}}</h3>
        <h3>{{$t('view.game.nextRoundStarts')}} {{getCurrentTime.second}}...</h3>
      </div>
    </div>
  </b-container>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions([
    ]),
  },
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
    ...mapGetters([
      'getCurrentTime',
    ])
  }
}
</script>