<template>
  <b-container class="my-3">

    <div class="d-flex justify-content-center">
      <div class="box-default rounded p-2 text-center d-inline-block">
        <h2>{{$t('view.game.endgameMessage')}}</h2>
        <h3>L'equipe {{getWinningTeam === $getConst('RED') ? 'bleue a perdu' : 'rouge a perdu'}}...</h3>
        <h5>{{$t('view.game.gameRedirect')}} {{getCurrentTime.second}}...</h5>
      </div>
    </div>



    <b-row class="my-3" align-h="center">
      <b-button @click="startNewGame" class="round-over-start-button mx-3">
        {{ $t('view.game.newGameButton' )}}
      </b-button>
      <b-button @click="goToHome" class="go-home-button mx-3">
        {{ $t('view.game.quitButton') }}
      </b-button>
    </b-row>

  </b-container>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  methods: {
    startNewGame: function() {
      this.forceTreeRerender();
      /* for now just go to list of rooms. eventually this 
      will be the actual room id associated with the game we just finished */
      this.$router.push('/rooms')
    },
    goToHome: function() {
      this.$router.push('/')
    },
    ...mapActions([
      'forceTreeRerender',
    ]),
  },
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
    ...mapGetters([
      'getCurrentPoints',
      'getCurrentTime',
      'getWinningTeam',
    ])
  }
  
}
</script>
